import { EVehicleStatus } from './vehicles.data';
import { EServerMode } from "./socket.data";
import { RoleAccountResponse } from './role.data';
import { CustomMapLayer } from './customMapLayer.data';


export enum EFE2Features {
    SHIFT_BOOK = 'SHIFT_BOOK',
    DANGEROUS_GOODS = 'DANGEROUS_GOODS',
    KOHLHAMMER= 'KOHLHAMMER',
    VEHICLE_CARDS= 'VEHICLE_CARDS',
    DEBUG = 'DEBUG',
    FIREBASE_STATUS = 'FIREBASE_STATUS',
    FIREBASE_FEEDBACK = 'FIREBASE_FEEDBACK',
    FIREBASE_AVAILABILITY = 'FIREBASE_AVAILABILITY',
    HERE_MAPS = 'HERE_MAPS',
    NAVLOG = 'NAVLOG',
    TRACKING = 'TRACKING',
    BOSCH_FORREST = 'BOSCH_FORREST'
}

export interface Keywords {
}

export interface Addresses {
}

export interface Types {
    MANUAL: number;
}

export interface Units {
    Test: number;
}

export interface Statistics {
    username: string;
    month: number;
    year: number;
    keywords: Keywords;
    addresses: Addresses;
    types: Types;
    dates: number[];
    times: number[];
    units: Units;
    cntAlarms: number;
}

export interface LicenceDetails {
    nbrOfPersons: number;
    nbrOfAM3s: number;
    nbrOfAMLs: number;
    nbrOfObjects: number;
    nbrOfDangerousGoods: number;
    nbrOfHereMaps: number;
    nbrOfKohlhammer: number;
    nbrOfVehicleCards: number;
    maxNbrOfAMLsReached: boolean;
    maxNbrOfAM3sReached: boolean;
    countAlarmObjectsForUser: number;
    maxNbrOfPersonsReached: boolean;
    maxNbrOfObjectsReached: boolean;
}

export interface InstalledVersion {
    version: string;
    type: string;
}

export interface CurrentVersion {
    id: number;
    name: string;
    url: string;
    version: string;
    releaseDate: string;
    changelog: string;
    type: string;
    customerType: string;
    isDeprecated: boolean;
}

export interface Version {
    installed: InstalledVersion;
    current: CurrentVersion;
    lastCheck: Date;
    upToDate: boolean;
}

export interface LogLevel {
    levelInt: number;
    levelStr: string;
}

export interface Licences {
    SHIFT_BOOK: number;
    DANGEROUS_GOODS: number;
    HERE_MAPS: number;
    NAVLOG: number;
    KOHLHAMMER: number;
    VEHICLE_CARDS: number;
    LOCATION_MAPS: number;
}

export interface Server {
    version: string;
    javaVersion: string;
    licenceEmail: string;
    licenceVendor: string;
    type: string;
    gae: string;
    logLevel: LogLevel;
    nbrOfPersons: number;
    nbrOfAmobile: number;
    nbrOfAM4: number;
    nbrOfRelais: number;
    nbrOfObjects: number;
    licences: Licences;
    mode: EServerMode;
    activeWebsocketConnections: number;
    externalAMwebDNS: string;
    externalDNS: string;
}

export interface Settings {
    wasserkarteInfoToken: string;
    roadblockAccess: string;
    lat: number;
    lng: number;
    street?: any;
    house?: any;
    city?: any;
    postalCode?: any;
    shiftBookStartHour: number;
    shiftBookStartMinutes: number;
    smsflatrateApiKey: string;
    fireboardAuthKey: string;
    geofenceStatusWithoutAlarm: EVehicleStatus;
    settingsSyncApagerPro: EFieldSyncType;
    settingsSyncEmail: EFieldSyncType;
    settingsSyncIssi: EFieldSyncType;
    settingsSyncMobil: EFieldSyncType;
    settingsSyncOsFunctions: EFieldSyncType;
    settingsSyncNote: EFieldSyncType;
    settingsSyncOsGroups: EFieldSyncType;
    settingsSyncAlarmGroup: EFieldSyncType;
    settingsSyncXmpp: EFieldSyncType;
    settingsSyncApiKey: string;
    settingsSyncEnabled: boolean;
    aMobileObjectTabs: AMobileObjectTab[];
    settingsSyncEnabledAlarm: boolean;
    settingsSyncEnabledTracking: boolean;
    accessToSmsGatewayAllowed:boolean;

    privacyAndTermsOfUseEnforcedByAdmin: boolean;
    privacyLink: string;
    termsOfUseLink: string;
}

export interface UserAccount {
    id: string;
    username: string;
    selfRegisterKey: string;
    personUsername: string;
    personID:string
    external: string;
    statistics: Statistics;
    licenceDetails: LicenceDetails;
    licenceType: string;
    version: Version;
    server: Server;
    exportTemplates: string[];
    hasAlarmObjectsInLicence: boolean;
    hasOnlineService: boolean;
    hasParkingPositions: boolean;
    hasVehicles: boolean;
    features: EFE2Features[];
    fosId: number;
    apiKeyReadOnly?: any;
    settings: Settings;
    inRevisionMode: boolean;
    loginType: ELoginType;
    dontAcceptNewAlarms: boolean;
    licenceValid: boolean;
    admin: boolean;
    deletionOfAlarmsDeactivated: boolean;
    customerSmsApiKeyEnforced: boolean;
    customerSmsApiKeyEnforcedWarning: boolean;
    instanceTitle: string;
    hasMailServiceEnabled: boolean;
    personShareFromUser: boolean;
    usersWithAccess: Map<string, string>;
    role: RoleAccountResponse;
    customMapLayer: CustomMapLayer;
    loggedInFromAdmin: boolean;
    sourceUsername: string;
    hasFidoSecureData: boolean;
}

export interface UserTokenResponse{
  token:string;
  username:string;
  loggedInFromAdmin:boolean;
    usersWithAccess: UserWithAccess[];
}

export interface UserWithAccess {
    userId: string;
    name: string;
}

export interface AccountResponse {
    token: string;
    qrCodeSecretKey: string;
    oneTimeToken: string;
    account: UserAccount;
    state: LoginTokenState;
}

export interface SecondFactorValidation {
    key: string
    username: string;
    oneTimeToken: string;
}

export enum LoginTokenState {
    OK = 'OK',
    SECOND_FACTOR_REQUIRED = 'SECOND_FACTOR_REQUIRED',
    FIRST_SECOND_FACTOR_LOGIN = 'FIRST_SECOND_FACTOR_LOGIN'
}

export interface LoginRequest {
    username: string;
    password: string;
    source: string;
}

export enum EFieldSyncType {
    NEVER = 'NEVER', ALWAYS = 'ALWAYS', INITIAL = 'INITIAL'
}

export enum ELoginType {
    APP = 'APP', USER = 'USER', PERSON = 'PERSON'
}

export enum ChangePasswordMode {
    AMOBILE_PRO = 'AMOBILE_PRO', FE2_MANAGER = 'FE2_MANAGER'
}

export enum AMobileObjectTab {
    INFORMATION = 'INFORMATION',
    HAZARD_HINTS = 'HAZARD_HINTS',
    SAFETY_HINTS = 'SAFETY_HINTS',
    CONTACTS = 'CONTACTS',
    BMA = 'BMA',
    RADIO = 'RADIO',
    EVENTS = 'EVENTS',
    DOCUMENTS = 'DOCUMENTS',
    DRIVES = 'DRIVES',
    STAGING_AREAS = 'STAGING_AREAS',
    HYDRANTS = 'HYDRANTS'
}

export interface FidoRegistration {
    id: string;
    alias: string;
    lastLogin: Date;
}