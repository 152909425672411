'use strict';

/* @ngInject */
import HelperService from "../../../../services/helper.service";
import {UserAdminContext} from "../../../../data/admin.data";
import {IRootScopeService, IScope} from "angular";
import AdminService from "../../../../services/admin.service";
require('./add.organisation.modal.css')
export default class AddOrganisationModalController{
  public $uibModalInstance:any;
  public $rootScope: IRootScopeService;
  public name:string;
  public password: string;
  public createOnlineService: boolean = true;
  public form: any;
  public okFunction: any;
  public helperService: HelperService;
  public isNameInvalid: boolean;
  public isLoading: boolean;
  public existingUsers: UserAdminContext[];
  public adminService: AdminService;

  constructor($uibModalInstance, okFunction,  helperService: HelperService, existingUsers, adminService: AdminService,
      private Notification: any,
      private $scope: IScope) {
    this.$uibModalInstance = $uibModalInstance;
    this.adminService = adminService;
    this.okFunction = okFunction;
    this.helperService = helperService;
    this.existingUsers = existingUsers;
    this.isNameInvalid = false;
    this.isLoading = false;
    this.generatePassword();

  }

  nameExists() {
    this.isNameInvalid = this.existingUsers.some(organisation => organisation.name === this.name ||organisation.organisation === this.name );
  }

  generatePassword(){
    this.password = this.helperService.generatePassword(6);
  }

  ok(){
    this.isLoading = true;
    this.adminService.createOrganisation(this.name, this.password, this.createOnlineService)
      .then(() => {
        this.isLoading = false;
        this.okFunction();
        this.$uibModalInstance.close();
      })
      .catch(err => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
  }
  cancel(){
    this.$uibModalInstance.close();
  }
}